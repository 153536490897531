import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import i18n from "./includes/i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import VueSelect from 'vue-select'
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css' // optional for styling
import CountryFlag from 'vue-country-flag-next'
import Toast from "vue-toastification";
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'

import "@/assets/toast_style.css";

const toastOptions = {
  position: "top-center",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.58,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: "button",
  rtl: false
}

import "./assets/style.css";

library.add(fas)
library.add(faWhatsapp)

const pinia = createPinia()
const app = createApp(App);

app.use(pinia);
app.use(i18n);
app.use(autoAnimatePlugin);

app.use(VueTippy, {
  defaultProps: { placement: 'top', arrow: true, theme: 'dark', allowHTML: 'true', zIndex: 9999, size: 'large' },
})

app.use(Toast, toastOptions);
app.use(router);

app.component("font-awesome-icon", FontAwesomeIcon);
app.component('VueDatePicker', VueDatePicker);
app.component('VueSelect', VueSelect.VueSelect);
app.component('CountryFlag', CountryFlag);

const env = import.meta.env.VITE_APP_ENV;

import * as Sentry from "@sentry/vue";

const feedback = Sentry.feedbackIntegration({
  // Disable injecting the default widget
  autoInject: false,
  // Additional SDK configuration goes in here, for example:
  colorScheme: "dark",
  showBranding: false,
  useSentryUser: {
    email: "email",
  },
  showName: false,
  themeDark: {
    background: "#262d63",
  },
});

app.provide("feedback", feedback);





// Initialize Sentry with the appropriate integrations


if (env != "local") {
  Sentry.init({
    app, // Your Vue app instance
    logErrors: true, // Keep Vue’s native error logging to console
    trackComponents: true,
    dsn: "https://4f20c5ec6a31746db30a9dc960302e7b@o4507979919917056.ingest.de.sentry.io/4507980023529552",
    // Tracing configuration
    tracesSampleRate: 0.05, // Capture 5% of the transactions
    tracePropagationTargets: ["localhost", "https://api.dev.myplazze.com", "https://api.myplazze.com"],

    // Session Replay configuration
    replaysSessionSampleRate: 0.0, // Disable recording of sessions unless there's an error or feedback
    replaysOnErrorSampleRate: 1.0, // Record sessions when an error occurs

    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    integrations: (defaultIntegrations) => {
      // Start with default integrations
      let integrations = defaultIntegrations;

      // Add custom integrations
      integrations.push(
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
        feedback
      );

      // Remove Vue integration in the local environment
      if (env === "local") {
        // integrations = integrations.filter((integration) => integration.name !== "Vue");
      }

      return integrations;
    },
  });
}



router.isReady().then(() => {
  app.mount("#app");
});

