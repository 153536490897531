// import * as API from "@/services/API";

// export default {
//     getUser(userId) {
//         return API.apiClient.get(`v2/user/${userId}`);
//     },
//     getUsers(page) {
//         return API.apiClient.get(`/users/?page=${page}`);
//     },
//     paginateUsers(link) {
//         return API.apiClient.get(link);
//     },
// };

import axios from "axios";

export const authClient = axios.create({
  baseURL : import.meta.env.VITE_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

export default {

    async getUsers(url,query) {
      const response = await authClient.post(url,query)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 

    async getUser(id) {
      const response = await authClient.get(`/api/v1/user/${id}`)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 



    async storeUser(user) {
      const response = await authClient.post('/api/v1/user/add', user)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 

    async updateUser(user) {
      const response = await authClient.put(`/api/v1/user/update`, user)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 

    async deleteUser(id) {
      const response = await authClient.delete(`/api/v1/user/${id}`)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 
    async restore_user(id) {
      const response = await authClient.get(`/api/v1/users/restore/${id}`)
        .then(response => response)
        .catch(error => { return error })

      return response
    } , 

    async exportUsers() {
      const response = await authClient.get('/api/v1/users/export')
        .then(response => response.data)
        .catch(error => { return error })

      return response
    },

    async getCategories() {
      const response = await authClient.get('/api/v1/user/categories')
        .then(response => response)
        .catch(error => { return error })

      return response
    },

    async getRoles() {
      const response = await authClient.get('/api/v1/user/roles')
        .then(response => response)
        .catch(error => { return error })

      return response
    },
    async getTown() {
      const response = await authClient.get(`/api/v1/town/config/data`)
        .then(response => response)
        .catch(error => error)
      return response
    },
    async get_provider() {
      const response = await authClient.get(`/api/v1/user/providers`)
        .then(response => response)
        .catch(error => error)
      return response
    },
    async get_clients() {
      const response = await authClient.get(`/api/v1/user/client`)
        .then(response => response)
        .catch(error => error)
      return response
    },

}
